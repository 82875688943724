import React from "react"
import { css } from "@emotion/core"
import { Container, Row, Col } from "reactstrap"

import Header from "../../components/header"
import Footer from "../../components/footer"

export default () => (
    <div>
        <Header/>
        <Container>
            <h1>Blog</h1>
        </Container>
        <Footer/>
    </div>
)
